import moment from 'moment';
import _ from 'lodash';
import qs from 'qs';
import pLimit from 'p-limit';
import { symptoReq, BearerT } from '../utils/auth/utils';
import { PatientData } from './bulkUploadParse';
import { isValidPhoneNumber } from '../bulkUploads/phoneValidate';
import { generateAuthHeader, assignNewSurvey, fillEncounterData } from '../bulkUploads/symptoUtils';
import { parseRace, parseEthnicity } from './dropdownUtils';
import {
  WARNING_PH_NUMBER_INVALID, PATIENT_FAILED, NO_PHONE, ATTEMPTING_NEW_PATIENT,
  SKIPPING_NON_MERCY, NOT_DISCHARGED, NOT_EMERGENCY, SERVICE_INTRO_PATIENT, LAST_ENCOUNTER_30,
  NEW_PATIENT, ENROLL_IN_ENCOUNTER,
} from '../bulkUploads/logs';
import { findPatientByMRN, attemptCreatePatient } from './symptoUtils';

const limit = pLimit(10);

const SERVICE_INTRO_SURVEY_ID = '8f55b888-fb34-41ae-9026-ada9a27b09d1';
const validatePhNumber = (
  phoneNumber: string, updateLogs: (msg: string) => void
) => {
  try {
    const phoneNumberFormatted = (phoneNumber || '').replace('CELL', '').replace('MCEL', '').trim();
    const isValidPh = isValidPhoneNumber(phoneNumberFormatted)
    if (!isValidPh) {
      updateLogs(`${WARNING_PH_NUMBER_INVALID} ${phoneNumber}.`);
    }
    return !isValidPh ? null : phoneNumberFormatted;
  } catch (e) {
    return null;
  }
}

const isInsuranceUmpqua = (insurancePlan: string) => (
  insurancePlan != null && (insurancePlan.toLowerCase().includes('umpqua') || insurancePlan.toLowerCase().includes('uha'))
)

const generateEncounterData = (patientData: PatientData) => {
  const payorList = patientData['Payers']?.split(';');
  const [payorListPartOne, payorListPartTwo] = payorList;
  return [
    {
      questionTitle: 'Patient Address',
      value: patientData['Patient Address'],
    },
    {
      questionTitle: 'Race',
      value: parseRace(patientData.Race),
    },
    {
      questionTitle: 'Ethnicity',
      value: parseEthnicity(patientData.Ethnicity),
    },
    {
      questionTitle: (isInsuranceUmpqua(payorListPartOne) || !isInsuranceUmpqua(payorListPartTwo)) ? 'Insurance ID' : 'Secondary Insurance #',
      value: patientData.MRN,
    },
    {
      questionTitle: 'In Person PCN Encounter',
      value: 'No',
    },
    {
      questionTitle: 'Chief Complaint',
      value: patientData['Chief Complaint'],
    },
    {
      questionTitle: 'Discharge Diagnosis',
      value: patientData['Diagnoses'],
    },
    {
      questionTitle: 'Primary Care Provider Name',
      value: patientData['Care Team'],
    },
    {
      questionTitle: 'Umpqua Health Patient',
      value: 'true',
    },
    {
      questionTitle: 'High Utilizer?',
      value: Number(patientData['ED Visit Count 6 Mo']) >= 3 ? 'Yes' : 'No',
    },
    {
      questionTitle: 'Reason for Navigation',
      value: _.compact([
        Number(patientData['ED Visit Count 6 Mo']) >= 3 ? 'High Utilizer' : null,
        'Insurance type',
      ]).join(','),
    },
    {
      questionTitle: 'Date of ED Visit / Referral',
      value: patientData['Discharge Date'] != null && patientData['Discharge Date'].trim().length > 0
        ? moment(patientData['Discharge Date'], 'MM/DD/YYYY').format('MM/DD/YYYY')
        : null,
    },
    {
      questionTitle: 'Provider / On-Site Coordinator Name',
      value: patientData['Attending Physician'],
    },
    {
      questionTitle: 'Primary Phone #',
      value: patientData['Patient Phone'],
    },
    {
      questionTitle: 'Email',
      value: patientData['Patient Email'],
    },
    {
      questionTitle: 'Insurance Plan',
      value: payorListPartOne,
    },
    {
      questionTitle: 'Secondary Insurance/Payor Plan',
      value: payorListPartTwo != null
        ? payorListPartTwo.trim()
        : null,
    },
    {
      questionTitle: 'Encounter #',
      value: patientData['Account Number'],
    },
    {
      questionTitle: 'Primary Navigator',
      value: 'Hauna Rose',
    },
    {
      questionTitle: 'Priority Level',
      value: '1) Low',
    },
    {
      questionTitle: 'Discharged',
      value: 'Yes',
    },
    {
      questionTitle: 'RX Prescribed',
      value: 'Unknown',
    },
    {
      questionTitle: 'Insurance Type / Payor',
      value: 'Managed Care Plan',
    },
    {
      questionTitle: 'Identification',
      value: 'Service Introduction',
    },
    {
      questionTitle: 'Status',
      value: 'Service Introduction',
    }

    // Referrals field is ignored
  ].filter(({ value }) => value != null && value.trim().length !== 0);
};

const createEncounter = async ({
  patientTvId, patientData, sendServiceIntro, auth, updateLogs, isNewPatient,
}: {
  patientTvId: string, patientData: PatientData, sendServiceIntro: boolean, auth: BearerT, isNewPatient: boolean, updateLogs: (log: string) => void
}) => {
  const { patientSurveyId } = await assignNewSurvey({
    authCode: auth,
    patientTvId,
    genericSurveyId: '2e8737f9-fccd-45a2-afb4-317cd39acc42',
  });
  updateLogs(isNewPatient ? NEW_PATIENT : ENROLL_IN_ENCOUNTER);

  const { didFill } = await fillEncounterData({
    authCode: auth,
    patientSurveyId,
    patientTvId,
    encounterData: generateEncounterData(patientData),
  });
  if (didFill && sendServiceIntro) {
    updateLogs(`Encounter created for ${patientTvId}`);

    // now creating service intro
    await assignNewSurvey({
      authCode: auth,
      patientTvId,
      genericSurveyId: SERVICE_INTRO_SURVEY_ID,
    });
    updateLogs(`${SERVICE_INTRO_PATIENT} - ${patientTvId}`);
  }
  return didFill;
};

const updatePatient = async (
  patientTvId: string, patientData: PatientData, authToken: BearerT, updateLogs: (log: string) => void
): Promise<{
  createEncounter: boolean,
  sendServiceIntro: boolean,
}> => {
  // find when last encounter created
  const params = qs.stringify({
    type: 'Date Filter',
    patientTvId,
    startDate: new Date(new Date().setFullYear(new Date().getFullYear() - 1)).getTime()
  });

  // first, update all patient attributes
  const existingPatient = await symptoReq(`/providers/patients/${patientTvId}`, {
    method: 'GET',
  }, generateAuthHeader(authToken));
  const patientDataUpdate = {
    firstName: patientData['First Name'],
    lastName: patientData['Last Name'],
    profilePicture: null,
    dob: moment(patientData['DOB'], 'MM/DD/YYYY').format('MM/DD/YYYY'),
    sex: null,
    timeZone: 'America/Los_Angeles',
    mrn: existingPatient.mrn,
    notificationType: existingPatient.notificationType,
    phoneNumber: validatePhNumber(existingPatient.phoneNumber, updateLogs) || existingPatient.phoneNumber,
    email: existingPatient.email,
  };
  await symptoReq(`/providers/patients/${patientTvId}/update`, {
    method: 'POST',
    body: JSON.stringify({
      ...patientDataUpdate,
    }),
  }, generateAuthHeader(authToken, true));

  const existingEncounters = (await symptoReq(`/providers/patientSurvey?${params}`, {
    method: 'GET',
  }, generateAuthHeader(authToken)) as {
    name: string,
    patientSurveyId: string,
    patientId: string,
    surveyTags: null | string[],
    type: 'instrument' | 'encounter',
    previewData: null | undefined | {
      'Encounter #': string,
    },
    startDate: number,
  }[]);

  const SERVICE_INTRO_TITLE = 'Service Intro, Mercy Roseburg';
  const lastServiceIntro = existingEncounters.find(({ name }) => name.includes(SERVICE_INTRO_TITLE));
  const lastServiceIntroWithin30Days = lastServiceIntro != null && moment().diff(moment(lastServiceIntro.startDate), 'days') < 30;
  // if last service intro sent less than 30 days ago, then don't send another
  if (lastServiceIntroWithin30Days) {
    updateLogs(LAST_ENCOUNTER_30);
  }

  const matchingEncounter = existingEncounters.find(({ previewData }) => previewData?.['Encounter #'] === patientData['Account Number']);

  return { createEncounter: matchingEncounter == null, sendServiceIntro: !lastServiceIntroWithin30Days };
};

export const uploadSymptoData = async (
  userData: PatientData[],
  authCode: BearerT,
  updateData: (index: number, log: string) => void,
  finishPatient: () => void,
) => {
  await userData.map((patientData, index) => (limit(async () => {
    const updateLogs: (log: string) => void = (log) => {
      updateData(index, log);
    };
    if (patientData['Visit Facility'] !== 'CHI Mercy Medical Center') {
      updateLogs(SKIPPING_NON_MERCY);
      finishPatient();
      return;
    }
    if (patientData['Discharge Disposition'] !== 'Discharged to home or self care (routine discharge)'
      && patientData['Discharge Disposition'] !== 'Left without being seen.'
      && patientData['Discharge Disposition'] !== 'Elopement') {
      updateLogs(NOT_DISCHARGED);
      finishPatient();
      return;
    }
    if (patientData['Major Class'] !== 'Emergency') {
      updateLogs(NOT_EMERGENCY);
      finishPatient();
      return;
    }

    try {
      const matchingPatient = await findPatientByMRN({
        patientData, auth: authCode,
      });

      if (matchingPatient != null) {
        const { createEncounter: shouldCreateEncounter, sendServiceIntro } = await updatePatient(
          matchingPatient.patientTvId, patientData, authCode, updateLogs,
        );
        if (shouldCreateEncounter) {
          await createEncounter({
            patientTvId: matchingPatient.patientTvId, patientData, sendServiceIntro, auth: authCode, updateLogs, isNewPatient: false
          });
        }
      } else if (matchingPatient == null) {
        updateLogs(ATTEMPTING_NEW_PATIENT);
        const phoneNumber = validatePhNumber(patientData['Patient Phone'], updateLogs);
        if (phoneNumber == null) {
          updateLogs(NO_PHONE);
        }
        const {
          patientTvId,
        } = await attemptCreatePatient({
          patientData: {
            firstName: patientData['First Name'],
            lastName: patientData['Last Name'],
            dob: moment(patientData['DOB'], 'MM/DD/YYYY').format('MM/DD/YYYY'),
            mrn: patientData['MRN'],
            phone: phoneNumber,
          },
          auth: authCode,
          updateLogs,
        });
        await createEncounter({
          patientTvId, patientData, sendServiceIntro: true, auth: authCode, updateLogs, isNewPatient: true
        });
      }
    } catch (e) {
      updateLogs(`${PATIENT_FAILED}. Reason ${e.message}...`);
      console.log(e);
    } finally {
      finishPatient();
    }
  })));
}

